import { cookie } from "integration-web-core--socle/js/assets/commons/_cookie";
const DEBUG_COOKIE = "privacyDebug";
const DEBUG_LEVEL = {
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4
};
const DEFAULT_DEBUG_LEVEL = 1;
const CONSOLE_PARAMS = ["%cPrivacy", "background-color: coral; border-radius: 5px; color: #fff; font-weight: bold; padding: 0px 5px"];
const isDebugLevel = value => {
  const debugCookieValue = Number(cookie.get(DEBUG_COOKIE));
  return (Number.isNaN(debugCookieValue) ? DEFAULT_DEBUG_LEVEL : debugCookieValue) >= value;
};
const clone = object => {
  if (object === null || typeof object !== "object" || object instanceof EventTarget || object instanceof Error) {
    return object;
  }
  if (object instanceof Array) {
    return object.map(clone);
  }
  if (object instanceof Object) {
    return Object.entries(object).reduce((acc, [key, value]) => {
      acc[key] = clone(value);
      return acc;
    }, {});
  }
  return undefined;
};
export default {
  debug(...message) {
    if (isDebugLevel(DEBUG_LEVEL.DEBUG)) {
      console.debug(...CONSOLE_PARAMS, ...message.map(clone));
    }
  },
  info(...message) {
    if (isDebugLevel(DEBUG_LEVEL.INFO)) {
      console.info(...CONSOLE_PARAMS, ...message.map(clone));
    }
  },
  warn(...message) {
    if (isDebugLevel(DEBUG_LEVEL.WARN)) {
      console.warn(...CONSOLE_PARAMS, ...message.map(clone));
    }
  },
  error(...message) {
    if (isDebugLevel(DEBUG_LEVEL.ERROR)) {
      console.error(...CONSOLE_PARAMS, ...message.map(clone));
    }
    if (message[0] instanceof Error && "DD_RUM" in window && typeof window.DD_RUM === "object" && window.DD_RUM !== null && "onReady" in window.DD_RUM && typeof window.DD_RUM.onReady === "function") {
      window.DD_RUM.onReady(() => {
        if ("DD_RUM" in window && typeof window.DD_RUM === "object" && window.DD_RUM !== null && "addError" in window.DD_RUM && typeof window.DD_RUM.addError === "function") window.DD_RUM.addError(message[0], {
          project_trangram: "KOBI MODULES - PRIVACY"
        });
      });
    }
  }
};