import FreemarkerModal from "@mozaic-ds/freemarker/js/modules/_modal";
import { cookie } from "integration-web-core--socle/js/assets/commons/_cookie";
import { getTmsInstance } from "integration-web-core--socle/js/assets/commons/_tms";
import CustomAccordion from "./utils/CustomAccordion";
import addHeadScript from "./utils/addHeadScript";
import format from "./utils/format";
import console from "./utils/console";
import removeTags from "./utils/removeTags";
var INIT_STATES;
(function (INIT_STATES) {
  INIT_STATES[INIT_STATES["NOT_INIT"] = 0] = "NOT_INIT";
  INIT_STATES[INIT_STATES["LOADING"] = 1] = "LOADING";
  INIT_STATES[INIT_STATES["LAZY"] = 2] = "LAZY";
  INIT_STATES[INIT_STATES["LAZY_LOADING"] = 3] = "LAZY_LOADING";
  INIT_STATES[INIT_STATES["INIT"] = 4] = "INIT";
})(INIT_STATES || (INIT_STATES = {}));
var ConsentMode;
(function (ConsentMode) {
  ConsentMode["Cookie"] = "cookie";
  ConsentMode["GetParam"] = "getParam";
  ConsentMode["WindowParam"] = "windowParam";
  ConsentMode["Modal"] = "modal";
})(ConsentMode || (ConsentMode = {}));
var PrivacyEvent;
(function (PrivacyEvent) {
  PrivacyEvent["Ready"] = "PrivacyReady";
  PrivacyEvent["ScriptReady"] = "PrivacyScriptReady";
  PrivacyEvent["Change"] = "PrivacyChange";
})(PrivacyEvent || (PrivacyEvent = {}));
const ROOT_ELEMENT_ID = "privacyModal";
const CONSENT_SCRIPT_URL = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
const CONSENT_GET_PARAMETER = "otConsentString";
const CONSENT_WINDOW_PARAMETER = "OTExternalConsent";
const MODAL_CLOSED_COOKIE = "OptanonAlertBoxClosed";
const CONSENT_COOKIE = "OptanonConsent";
const TMS_EVENT = {
  TYPE: "cdl_event",
  NAME: {
    BUTTON_CLICK: "button.click",
    CONSENT_REPLY: "consent.reply",
    POPIN_DISPLAY: "popin.display"
  },
  DATA: {
    BUTTON: {
      ACCEPT_ALL: "accept all",
      CUSTOM: "set custom preferences",
      CONTINUE_WITHOUT_ACCEPTING: "continue without accepting",
      SAVE_AND_CONTINUE: "save and continue",
      SEE_MORE: "see more details"
    },
    LOCATION: {
      MAIN: "consent popin",
      CUSTOM: "consent preferences popin"
    },
    FEATURE: "consent"
  }
};
const tms = getTmsInstance();
export default (superclass => class Modal extends superclass {
  constructor() {
    var _a, _b;
    super(ROOT_ELEMENT_ID);
    this.initState = INIT_STATES.NOT_INIT;
    this.optanonLoaded = false;
    this.legacy = false;
    this.toggles = [];
    this.active = false;
    const rootElement = document.getElementById(`component-${ROOT_ELEMENT_ID}`);
    if (!rootElement) throw new ReferenceError("DOM root element not found");
    this.rootElement = rootElement;
    const domainScript = (_b = (_a = this.rootElement) === null || _a === void 0 ? void 0 : _a.dataset) === null || _b === void 0 ? void 0 : _b.domainScript;
    if (!domainScript) throw new ReferenceError("Domain script not found");
    this.legacy = !!this.rootElement.dataset.legacy;
    this.string = JSON.parse(this.rootElement.dataset.string || "");
  }
  get privacyObject() {
    const _this = this;
    return {
      ready(...args) {
        return _this.ready(...args);
      },
      openModal(...args) {
        return _this.openModal(...args);
      },
      isGroupOptin(...args) {
        return _this.isGroupOptin(...args);
      }
    };
  }
  init(queue = [], forceLoad = false) {
    if ([INIT_STATES.LOADING, INIT_STATES.LAZY_LOADING, INIT_STATES.INIT].indexOf(this.initState) !== -1 || !forceLoad && this.initState === INIT_STATES.LAZY) {
      return;
    }
    this.initState = this.initState === INIT_STATES.NOT_INIT ? INIT_STATES.LOADING : INIT_STATES.LAZY_LOADING;
    if (this.initState === INIT_STATES.LOADING) this.processQueue(queue);
    const usedWindowParam = window[CONSENT_WINDOW_PARAMETER];
    const usedGetParam = new URL(window.location.href).searchParams.get(CONSENT_GET_PARAMETER);
    const forceConsent = !!(usedWindowParam || usedGetParam);
    if (!forceLoad && !forceConsent && cookie.get(MODAL_CLOSED_COOKIE)) {
      this.initState = INIT_STATES.LAZY;
      console.info("💤 Privacy scripts in lazy load");
      window.dispatchEvent(new CustomEvent(PrivacyEvent.Ready));
      return;
    }
    console.info("⏲ Loading privacy scripts", {
      legacy: this.legacy,
      usedWindowParam,
      usedGetParam
    });
    addHeadScript(CONSENT_SCRIPT_URL, {
      domainScript: this.rootElement.dataset.domainScript,
      ignoreHtml: `${!this.legacy}`
    });
    const alertBoxCookie = cookie.get(MODAL_CLOSED_COOKIE);
    if (this.legacy) {
      this.scriptInit();
    } else {
      const isCookieUrl = new URL(this.rootElement.dataset.cookieUrl || "", window.location.origin).href === window.location.href;
      this.scriptInit(!alertBoxCookie && !forceConsent && !isCookieUrl, forceConsent);
    }
  }
  scriptInit(initModal, saveConsent = false) {
    window.OptanonWrapper = () => {
      if (this.optanonLoaded) return;
      this.optanonLoaded = true;
      const wasLazy = this.initState === INIT_STATES.LAZY_LOADING;
      initModal !== undefined && this.initModal(initModal, saveConsent);
      window.OneTrust.OnConsentChanged(() => {
        window.dispatchEvent(new CustomEvent(PrivacyEvent.Change));
      });
      this.initState = INIT_STATES.INIT;
      console.info("🏁 Privacy scripts loaded");
      window.dispatchEvent(new CustomEvent(PrivacyEvent.ScriptReady));
      !wasLazy && window.dispatchEvent(new CustomEvent(PrivacyEvent.Ready));
    };
  }
  processQueue(queue) {
    console.debug("⚙ Process queue", queue);
    let element = undefined;
    while (element = queue.shift()) {
      switch (element.shift()) {
        case "ready":
          this.ready(...element);
          break;
        case "openModal":
          this.openModal(...element);
          break;
      }
    }
  }
  ready(callback) {
    if ([INIT_STATES.LAZY, INIT_STATES.INIT].indexOf(this.initState) !== -1) {
      callback();
    } else {
      window.addEventListener(PrivacyEvent.Ready, callback, {
        once: true
      });
    }
  }
  scriptReady(callback) {
    if (this.initState === INIT_STATES.INIT) {
      callback();
    } else {
      window.addEventListener(PrivacyEvent.ScriptReady, callback, {
        once: true
      });
    }
  }
  initModal(openModal, forceSave = false) {
    const hostHTML = document.getElementById("privacy-module--host");
    if (!hostHTML) return;
    this.hostsTemplate = hostHTML.children[0].cloneNode(true);
    this.bindHTMLComponents();
    forceSave && this.saveConsent();
    openModal && this.openModal();
  }
  bindHTMLComponents() {
    this.bindAccordions();
    this.bindButtons();
    this.bindModals();
    this.bindToggles();
  }
  bindAccordions() {
    const domainData = window.OneTrust.GetDomainData();
    const groupsElement = document.getElementById("privacy-module--groups");
    if (!groupsElement) return;
    const usedWindowParam = window[CONSENT_WINDOW_PARAMETER];
    const usedGetParam = new URL(window.location.href).searchParams.get(CONSENT_GET_PARAMETER);
    domainData.Groups.filter(group => group.Hosts.length > 0).forEach((group, index) => {
      const link = document.createElement("button");
      link.classList.add("mc-link", "mt-body-s", "mu-mt-100");
      link.textContent = this.string.hostDetails;
      link.addEventListener("click", e => {
        e.preventDefault();
        this.generateHostModal(group.CustomGroupId);
      });
      let checked = false;
      if (group.Status === "always active") {
        checked = true;
      } else if (usedWindowParam) {
        checked = this.isGroupOptin(group.CustomGroupId, ConsentMode.WindowParam);
      } else if (usedGetParam) {
        checked = this.isGroupOptin(group.CustomGroupId, ConsentMode.GetParam);
      } else {
        checked = this.isGroupOptin(group.CustomGroupId, ConsentMode.Cookie);
      }
      const customAccordion = new CustomAccordion({
        id: `accPrivacyGroups-${index}`,
        label: group.GroupName,
        size: "s",
        configToggle: {
          name: "customConsentToggle",
          value: group.CustomGroupId,
          disabled: group.Status === "always active",
          checked
        },
        content: removeTags(group.GroupDescription)
      });
      customAccordion.accordionContent.classList.add("mt-body-s");
      customAccordion.accordionContent.appendChild(link);
      groupsElement.appendChild(customAccordion.accordion);
    });
  }
  bindButtons() {
    const closeNoOptinButton = this.rootElement.querySelector(".js-modal-privacy .js-modal-close");
    if (!closeNoOptinButton) return;
    closeNoOptinButton.classList.remove("mc-modal__close");
    closeNoOptinButton.classList.add("mc-link", "mc-link--s", "alt-modal__close");
    closeNoOptinButton.textContent = this.string.closeNoOptin;
    const closeNoOptinButtonSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    closeNoOptinButtonSvg.classList.add("mc-link__icon");
    closeNoOptinButtonSvg.setAttribute("viewBox", "0 0 16 16");
    closeNoOptinButtonSvg.setAttribute("aria-hidden", "true");
    const closeNoOptinButtonPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
    closeNoOptinButtonPath.setAttribute("d", "M5.293 3.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L8.586 8 5.293 4.707a1 1 0 010-1.414z");
    closeNoOptinButtonSvg.appendChild(closeNoOptinButtonPath);
    closeNoOptinButton.appendChild(closeNoOptinButtonSvg);
    const linkCookieMain = this.rootElement.querySelector(".js-modal-privacy__cookie-link-main");
    const linkCookieCustom = this.rootElement.querySelector(".js-modal-privacy__cookie-link-custom");
    const acceptButton = this.rootElement.querySelector(".js-modal-privacy-button-accept");
    const customButton = this.rootElement.querySelector(".js-modal-privacy-button-custom");
    const acceptAllButton = this.rootElement.querySelector(".js-modal-privacy-button-accept-all");
    const saveButton = this.rootElement.querySelector(".js-modal-privacy-button-save");
    const backButton = this.rootElement.querySelector(".js-modal-privacy-button-back");
    if (linkCookieMain) {
      linkCookieMain.addEventListener("click", () => {
        tms.pushEvent(linkCookieMain, TMS_EVENT.TYPE, {
          event_name: TMS_EVENT.NAME.BUTTON_CLICK,
          event_data: {
            button_name: TMS_EVENT.DATA.BUTTON.SEE_MORE,
            button_location: TMS_EVENT.DATA.LOCATION.MAIN
          }
        });
      });
    }
    if (!linkCookieCustom) return;
    linkCookieCustom.addEventListener("click", () => {
      tms.pushEvent(linkCookieCustom, TMS_EVENT.TYPE, {
        event_name: TMS_EVENT.NAME.BUTTON_CLICK,
        event_data: {
          button_name: TMS_EVENT.DATA.BUTTON.SEE_MORE,
          button_location: TMS_EVENT.DATA.LOCATION.CUSTOM
        }
      });
    });
    closeNoOptinButton.addEventListener("click", () => {
      tms.pushEvent(closeNoOptinButton, TMS_EVENT.TYPE, {
        event_name: TMS_EVENT.NAME.BUTTON_CLICK,
        event_data: {
          button_name: TMS_EVENT.DATA.BUTTON.CONTINUE_WITHOUT_ACCEPTING,
          button_location: TMS_EVENT.DATA.LOCATION.MAIN
        }
      });
      window.addEventListener(PrivacyEvent.Change, () => {
        tms.pushEvent(closeNoOptinButton, TMS_EVENT.TYPE, {
          event_name: TMS_EVENT.NAME.CONSENT_REPLY,
          event_data: {
            button_name: TMS_EVENT.DATA.BUTTON.CONTINUE_WITHOUT_ACCEPTING,
            button_location: TMS_EVENT.DATA.LOCATION.MAIN,
            categories_optin: this.getOptinGroups()
          }
        });
      }, {
        once: true
      });
    });
    if (!acceptButton) return;
    acceptButton.addEventListener("click", () => {
      tms.pushEvent(acceptButton, TMS_EVENT.TYPE, {
        event_name: TMS_EVENT.NAME.BUTTON_CLICK,
        event_data: {
          button_name: TMS_EVENT.DATA.BUTTON.ACCEPT_ALL,
          button_location: TMS_EVENT.DATA.LOCATION.MAIN
        }
      });
      window.addEventListener(PrivacyEvent.Change, () => {
        tms.pushEvent(acceptButton, TMS_EVENT.TYPE, {
          event_name: TMS_EVENT.NAME.CONSENT_REPLY,
          event_data: {
            button_name: TMS_EVENT.DATA.BUTTON.ACCEPT_ALL,
            button_location: TMS_EVENT.DATA.LOCATION.MAIN,
            categories_optin: this.getOptinGroups()
          }
        });
      }, {
        once: true
      });
    });
    if (!customButton) return;
    customButton.addEventListener("click", () => {
      tms.pushEvent(customButton, TMS_EVENT.TYPE, {
        event_name: TMS_EVENT.NAME.BUTTON_CLICK,
        event_data: {
          button_name: TMS_EVENT.DATA.BUTTON.CUSTOM,
          button_location: TMS_EVENT.DATA.LOCATION.MAIN
        }
      });
    });
    if (!acceptAllButton) return;
    acceptAllButton.addEventListener("click", () => {
      tms.pushEvent(acceptAllButton, TMS_EVENT.TYPE, {
        event_name: TMS_EVENT.NAME.BUTTON_CLICK,
        event_data: {
          button_name: TMS_EVENT.DATA.BUTTON.ACCEPT_ALL,
          button_location: TMS_EVENT.DATA.LOCATION.CUSTOM
        }
      });
      window.addEventListener(PrivacyEvent.Change, () => {
        tms.pushEvent(acceptAllButton, TMS_EVENT.TYPE, {
          event_name: TMS_EVENT.NAME.CONSENT_REPLY,
          event_data: {
            button_name: TMS_EVENT.DATA.BUTTON.ACCEPT_ALL,
            button_location: TMS_EVENT.DATA.LOCATION.CUSTOM,
            categories_optin: this.getOptinGroups()
          }
        });
      }, {
        once: true
      });
    });
    if (!saveButton) return;
    saveButton.addEventListener("click", () => {
      tms.pushEvent(saveButton, TMS_EVENT.TYPE, {
        event_name: TMS_EVENT.NAME.BUTTON_CLICK,
        event_data: {
          button_name: TMS_EVENT.DATA.BUTTON.SAVE_AND_CONTINUE,
          button_location: TMS_EVENT.DATA.LOCATION.CUSTOM
        }
      });
      window.addEventListener(PrivacyEvent.Change, () => {
        tms.pushEvent(saveButton, TMS_EVENT.TYPE, {
          event_name: TMS_EVENT.NAME.CONSENT_REPLY,
          event_data: {
            button_name: TMS_EVENT.DATA.BUTTON.SAVE_AND_CONTINUE,
            button_location: TMS_EVENT.DATA.LOCATION.CUSTOM,
            categories_optin: this.getOptinGroups()
          }
        });
      }, {
        once: true
      });
    });
    closeNoOptinButton.addEventListener("click", () => {
      this.optoutAll();
      this.saveConsent();
      this.closeAllModals();
    });
    acceptButton.addEventListener("click", () => {
      this.optinAll();
      this.saveConsent();
      this.closeAllModals();
    });
    customButton.addEventListener("click", () => {
      this.customModal.open();
    });
    acceptAllButton.addEventListener("click", () => {
      this.optinAll();
      this.saveConsent();
      this.closeAllModals();
    });
    saveButton.addEventListener("click", () => {
      this.saveConsent();
      this.closeAllModals();
    });
    if (!backButton) return;
    backButton.addEventListener("click", () => {
      this.hostModal.close();
    });
  }
  bindModals() {
    this.modal = new FreemarkerModal({
      classContent: "js-modal-privacy",
      onOpen: () => {
        tms.pushEvent(window, TMS_EVENT.TYPE, {
          event_name: TMS_EVENT.NAME.POPIN_DISPLAY,
          event_data: {
            popin_name: TMS_EVENT.DATA.LOCATION.MAIN,
            feature_name: TMS_EVENT.DATA.FEATURE
          }
        });
      }
    });
    this.modal.modal.querySelector(".mc-modal__dialog").classList.add("is-blocked");
    this.customModal = new FreemarkerModal({
      classContent: "js-modal-privacy-custom",
      onOpen: () => {
        tms.pushEvent(window, TMS_EVENT.TYPE, {
          event_name: TMS_EVENT.NAME.POPIN_DISPLAY,
          event_data: {
            popin_name: TMS_EVENT.DATA.LOCATION.CUSTOM,
            feature_name: TMS_EVENT.DATA.FEATURE
          }
        });
      },
      onClose: () => {
        if (this.active) {
          setTimeout(() => this.modal.open(), 0);
        }
      }
    });
    this.hostModal = new FreemarkerModal({
      classContent: "js-modal-privacy-host",
      onClose: () => {
        setTimeout(() => this.customModal.open(), 0);
      }
    });
  }
  bindToggles() {
    const toggles = this.rootElement.querySelectorAll('[name="customConsentToggle"]');
    toggles.forEach(toggle => {
      this.toggles.push(toggle);
    });
  }
  generateHostModal(groupSelected) {
    if (groupSelected) {
      const domainData = window.OneTrust.GetDomainData();
      const group = domainData.Groups.find(g => g.CustomGroupId === groupSelected);
      if (group) {
        const hostsElement = document.getElementById("privacy-module--host");
        if (!hostsElement) return;
        hostsElement.innerHTML = "";
        const hosts = [{
          DisplayName: this.string.cookieFirstParty,
          Cookies: group.FirstPartyCookies
        }, ...group.Hosts];
        hosts.forEach((host, index) => {
          const hostAccordion = new CustomAccordion({
            id: `accPrivacyHosts-${index}`,
            label: host.DisplayName,
            size: "s",
            content: ""
          });
          hostAccordion.accordionContent.appendChild(this.createHostDOM(host, group.GroupName));
          hostsElement.appendChild(hostAccordion.accordion);
        });
      }
      this.hostModal.open();
    }
  }
  createHostDOM(host, groupName) {
    const mainDiv = document.createElement("div");
    host.Cookies.forEach(c => {
      const node = this.hostsTemplate.cloneNode(true);
      const nameDOM = node.querySelector("[data-bind='name']");
      if (!nameDOM) return;
      nameDOM.textContent = c.Name;
      const hostDOM = node.querySelector("[data-bind='host']");
      if (!hostDOM) return;
      hostDOM.textContent = c.Host;
      const typeDOM = node.querySelector("[data-bind='type']");
      if (!typeDOM) return;
      typeDOM.textContent = host.HostId ? this.string.cookieThirdParty : this.string.cookieFirstParty;
      const categoryDOM = node.querySelector("[data-bind='category']");
      if (!categoryDOM) return;
      categoryDOM.textContent = groupName;
      const descriptionDOM = node.querySelector("[data-bind='description']");
      if (!descriptionDOM) return;
      if (c.description) {
        descriptionDOM.innerHTML = format(c.description);
      } else {
        const parentElement = descriptionDOM.parentElement;
        if (parentElement) parentElement.style.display = "none";
      }
      mainDiv.appendChild(node);
    });
    return mainDiv;
  }
  isGroupOptin(group, consentMode = ConsentMode.Cookie) {
    let cookieGroup = undefined;
    switch (consentMode) {
      case ConsentMode.Cookie:
        {
          const consent = cookie.get(CONSENT_COOKIE);
          if (!consent) {
            return false;
          }
          const groups = consent.split("&").find(g => g.indexOf("groups=") === 0);
          if (!groups) {
            return false;
          }
          cookieGroup = decodeURIComponent(groups.substring(7)).split(",").find(s => s.indexOf(group) === 0);
          break;
        }
      case ConsentMode.GetParam:
        {
          const consentString = new URL(window.location.href).searchParams.get(CONSENT_GET_PARAMETER);
          if (consentString) {
            cookieGroup = consentString.split(",").find(s => s.indexOf(group) === 0);
          }
          break;
        }
      case ConsentMode.WindowParam:
        {
          const consentVar = window[CONSENT_WINDOW_PARAMETER];
          if (consentVar) {
            cookieGroup = consentVar.groups.split(",").find(s => s.indexOf(group) === 0);
          }
          break;
        }
      case ConsentMode.Modal:
        {
          const toggle = this.toggles.find(s => s.value === group);
          cookieGroup = toggle && `${toggle.value}:${Number(toggle.checked)}`;
          break;
        }
    }
    return cookieGroup !== undefined && cookieGroup.split(":")[1] === "1";
  }
  getOptinGroups(consentMode) {
    return this.toggles.reduce((acc, toggle) => {
      this.isGroupOptin(toggle.value, consentMode) && acc.push(toggle.value);
      return acc;
    }, []);
  }
  openModal(customOnly = false) {
    if (this.initState !== INIT_STATES.INIT) {
      this.init(undefined, true);
    }
    this.scriptReady(() => {
      console.info("✓ Open modal");
      if (this.legacy) {
        window.Optanon.ToggleInfoDisplay();
      } else if (customOnly) {
        this.customModal.open();
      } else {
        this.active = true;
        this.modal.open();
      }
    });
  }
  optinAll() {
    this.toggles.forEach(toggle => {
      toggle.checked = toggle.disabled ? toggle.checked : true;
    });
  }
  optoutAll() {
    this.toggles.forEach(toggle => {
      toggle.checked = toggle.disabled ? toggle.checked : false;
    });
  }
  saveConsent() {
    const modalValuesStr = this.getOptinGroups(ConsentMode.Modal).join(",");
    if (cookie.get(MODAL_CLOSED_COOKIE) && modalValuesStr === this.getOptinGroups(ConsentMode.Cookie).join(",")) {
      console.info("💾 Same consent, no save required", modalValuesStr);
      return;
    }
    console.info("💾 Save consent", modalValuesStr);
    this.clearCookies();
    const isAllowAll = this.toggles.every(toggle => toggle.checked || toggle.disabled);
    if (isAllowAll) {
      return window.OneTrust.AllowAll();
    }
    const isRejectAll = this.toggles.every(toggle => !toggle.checked || toggle.disabled);
    if (isRejectAll) {
      return window.OneTrust.RejectAll();
    }
    const consent = [];
    this.toggles.forEach(toggle => {
      consent.push(`${toggle.value}:${Number(toggle.checked)}`);
    });
    window.OneTrust.UpdateConsent("Category", consent.join(","));
  }
  clearCookies() {
    const domainData = window.OneTrust.GetDomainData();
    domainData.Groups.forEach(group => {
      if (!this.isGroupOptin(group.OptanonGroupId, ConsentMode.Modal)) {
        console.debug("🧹 Clear cookies", {
          group: group.OptanonGroupId,
          cookies: group.Cookies
        });
        group.Cookies.forEach(c => {
          const name = encodeURIComponent(c.Name);
          if (cookie.get(name)) {
            const domain = encodeURIComponent(c.Host);
            document.cookie = `${name}=; Max-Age=0; path=/${domain !== window.location.hostname ? `; domain=${domain}` : ""}`;
          }
        });
      }
    });
  }
  closeAllModals() {
    console.info("✕ Close all modals");
    this.active = false;
    this.hostModal.displayed && this.hostModal.close();
    this.customModal.displayed && this.customModal.close();
    this.modal.displayed && this.modal.close();
  }
});