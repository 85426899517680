import Modal from "./modal.class";
import console from "./utils/console";
function main() {
  window.lm.DOMReady(() => {
    try {
      const privacy = new (Modal(window.lm.Composant))();
      Object.assign(window.privacy, privacy.privacyObject);
      privacy.init(window.privacy._q);
      delete window.privacy._q;
    } catch (e) {
      console.error(e);
    }
  });
}
if (document.currentScript) {
  main();
}
export default main;