import Accordion from "@mozaic-ds/freemarker/js/modules/_accordion";
let nInstances = 0;
function createDOM({
  id,
  label,
  size,
  configToggle,
  content = ""
}) {
  const mainDiv = document.createElement("div");
  mainDiv.classList.add("mc-accordion", "js-accordion", `mc-accordion--${size}`, "mc-accordion--checkable");
  const header = document.createElement("div");
  header.classList.add("mc-accordion__header");
  mainDiv.appendChild(header);
  if (configToggle) {
    const toggleWrapper = document.createElement("div");
    toggleWrapper.classList.add("mc-toggle", "mc-toggle--hide-label");
    header.appendChild(toggleWrapper);
    const input = document.createElement("input");
    input.classList.add("mc-toggle__input");
    input.type = "checkbox";
    input.id = `${configToggle.name}--${nInstances}`;
    input.name = configToggle.name;
    input.value = configToggle.value;
    input.disabled = configToggle.disabled;
    input.checked = configToggle.checked;
    toggleWrapper.appendChild(input);
    const toggleLabel = document.createElement("label");
    toggleLabel.classList.add("mc-toggle__label");
    toggleLabel.htmlFor = `${configToggle.name}--${nInstances}`;
    toggleWrapper.appendChild(toggleLabel);
    const toggleLabelSpan = document.createElement("span");
    toggleLabelSpan.classList.add("mc-toggle__content");
    toggleLabelSpan.textContent = label || "";
    toggleLabel.appendChild(toggleLabelSpan);
  }
  const title = document.createElement("div");
  title.classList.add("mc-accordion__title");
  header.appendChild(title);
  const button = document.createElement("button");
  button.classList.add("mc-accordion__trigger", "js-accordion__trigger");
  button.id = id;
  button.type = "button";
  button.setAttribute("aria-controls", `${id}__content`);
  button.setAttribute("aria-expanded", "false");
  button.textContent = label;
  title.appendChild(button);
  const contentWrapper = document.createElement("div");
  contentWrapper.classList.add("mc-accordion__content", "js-accordion__content");
  contentWrapper.id = `${id}__content`;
  contentWrapper.setAttribute("aria-hidden", "true");
  contentWrapper.setAttribute("aria-labelledby", id);
  mainDiv.appendChild(contentWrapper);
  const contentWrapperP = document.createElement("p");
  contentWrapperP.textContent = content;
  contentWrapper.appendChild(contentWrapperP);
  return mainDiv;
}
export default class CustomAccordion extends Accordion {
  constructor(options) {
    nInstances++;
    super(createDOM(options));
  }
}